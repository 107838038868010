<template>
	<div>
		<!--同步金健劳工实名制打卡数据-->
		<el-divider>同步金健劳工实名制打卡数据</el-divider>
		<div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;margin-top: 2%;margin-bottom: 2%">
			<el-date-picker
				v-model="jinjian_date"
				type="date"
				placeholder="请选择同步时间"
				format="YYYY-MM-DD"
				value-format="YYYY-MM-DD"
			/>
			<el-select v-model="bdst" class="m-2" placeholder="请选择工区" size="small" style="width: 60%">
				<el-option
					v-for="item in bdsts"
					:key="item.value"
					:label="item.label"
					:value="item.value"
				/>
			</el-select>
			<el-input v-model="jinjian_token" style="width: 80%" placeholder="请输入Token"/>
			<el-input v-model="jinjian_totals" style="width: 60%" placeholder="请输入总数据量"/>
			<el-input v-model="jinjian_page" style="width: 60%" placeholder="请输入页数"/>
			<el-button type="primary" style="width: 60%" @click="sync_jinjian_data">同步数据</el-button>
		</div>
		<el-divider/>
	<!--同步进出洞打卡数据-->
		<el-divider>同步进出洞打卡数据</el-divider>
		<div style="margin-top: 2%;margin-bottom: 2%;display: grid; grid-template-columns: 1fr 1fr">
			<el-date-picker
				v-model="gps_date"
				type="date"
				placeholder="请选择同步时间"
				format="YYYY-MM-DD"
				value-format="YYYY-MM-DD"
			/>
			<el-button type="primary" style="width: 90%" @click="sync_peo_other_data">同步数据</el-button>
		</div>

	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowDate} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			jinjian_token: '',
			jinjian_totals: '',
			jinjian_date: '',
			gps_date: '',
			bdst: '',
			bdsts: [
				{
					value: '2标',
					label: '2标',
				},
				{
					value: '5标',
					label: '5标',
				}],
			jinjian_page: ''
		}
	},
	created() {
		let today = new Date()
		this.jinjian_date = setNowDate(new Date(today.getTime() - 24 * 60 * 60 * 1000))
	},
	methods: {
		sync_jinjian_data(){
			this.axios.get('/peopleGpsBasal/getJinJianData', (response) => {
				if (response.obj){
					ElMessage.success('同步成功!')
				}else {
					ElMessage.error('同步失败!')
				}
			},{
				query_date: this.jinjian_date,
				limit: this.jinjian_totals,
				token: this.jinjian_token,
				bdst: this.bdst,
				page: this.jinjian_page
			})
		},
		sync_peo_other_data(){
			if (this.gps_date === ''){
				ElMessage.error('请选择同步日期!')
			}else {
				this.axios.get('/peoGpsResult/getResultData', (response) => {
					if (response.obj){
						ElMessage.success('同步成功!')
					}else {
						ElMessage.error('同步失败!')
					}
				},{
					dateStr: this.gps_date
				})
			}

		}
	}
}
</script>

<style>

</style>