<template>
	<div>
		<div>
			<el-select v-model="bdst" class="m-2" placeholder="请选择工区"  style="width: 60%">
				<el-option
					v-for="item in bdsts"
					:key="item.value"
					:label="item.label"
					:value="item.value"
				/>
			</el-select>
			<el-button type="primary" @click="jump">大屏展示</el-button>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			bdst: '',
			bdsts: [{
				value: '郭达山隧道横洞',
				label: '郭达山隧道横洞'
			}, {
					value: '康定2号隧道2号斜井',
					label: '康定2号隧道2号斜井'
				},{
					value: '康定2号隧道1号横洞',
					label: '康定2号隧道1号横洞'
				},{
				value: '康定2号隧道进口',
				label: '康定2号隧道进口'
			},{
				value: '康定1号隧道1号横洞',
				label: '康定1号隧道1号横洞'
			}]
		}
	},
	created() {

	},
	methods: {
		jump(){
			const time = Date.parse(new Date()) + this.uuid();
			this.$router.push({
				name: 'Peo_gps_data_show',
				params: {
					key: time,
					opername: this.bdst
				}
			});
		},
		uuid() {
			let s = [];
			let hexDigits = '0123456789abcdef';
			for (let i = 0; i < 36; i++) {
				s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
			}
			s[14] = '4';
			s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
			s[8] = s[13] = s[18] = s[23] = '-';

			this.uuidA = s.join('');
			return this.uuidA;
		},
	}
}
</script>

<style>

</style>