<template>
	<div>
		<div>
			<Header></Header>
		</div>
		<div id="admindiv1">
			<div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item style="font-size: 13px">人员定位管理</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>

		<div id="forms_div2">
			<el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
				<el-tab-pane label="数据同步" name="a"><Data_manage v-if="a" /></el-tab-pane>
				<el-tab-pane label="数据查询" name="e"><Data_query v-if="e" /></el-tab-pane>
				<el-tab-pane label="大屏展示" name="b"><Peo_gps_show v-if="b" /></el-tab-pane>
				<!--<el-tab-pane label="在线情况查询" name="c"><Select_clock v-if="c" /></el-tab-pane>-->
			</el-tabs>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import Header from "@/views/header";
import Save_index from "@/views/material/dbd/save/save_index";
import {mapGetters} from "vuex";
import Data_manage from "@/views/peo_gps/data_manage.vue";
import Data_query from "@/views/peo_gps/data_query.vue";
import Peo_gps_show from "@/views/peo_gps/peo_gps_show.vue";

export default {
	name: "index",
	components: {Header, Data_manage, Data_query, Peo_gps_show},
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			activeName: 'a',
			a: true,
			e: false,
			b: false,
			c: false
		}
	},
	created() {
		if (!this.$route.params.key){
			this.$router.push("/manage")
		}
	},
	methods: {
		handleClick(tab, event){
			sessionStorage.setItem('dbd_index_tab', tab.props.name)
			if (tab.props.name === 'a'){
				this.a = true
				this.e = false
				this.b = false
				this.c = false
			}
			if (tab.props.name === 'e'){
				this.a = false
				this.e = true
				this.b = false
				this.c = false
			}
			if (tab.props.name === 'b'){
				this.a = false
				this.b = true
				this.e = false
				this.c = false
			}
			if (tab.props.name === 'c'){
				this.a = false
				this.b = false
				this.e = false
				this.c = true
			}
		}
	}
}
</script>

<style scoped>
#admindiv1{
	border-radius: 5px;
	background-color: white;
	display: flex;
	width: 95%;
	height: 40px;
	margin: 10px auto 30px;
	justify-content: space-between;
}

#forms_div2{
	border-radius: 5px;
	margin: 0 auto;
	background-color: white;
	width: 95%;
	padding-top: 20px;
	padding-bottom: 20px;
	min-height: 500px;
}
</style>
