<template>
	<div>
		<!--	选择日期-->
		<div>
			<el-date-picker
				v-model="query_date"
				type="date"
				placeholder="请选择同步时间"
				format="YYYY-MM-DD"
				value-format="YYYYMMDD"
				@change="query_clock_data"
			/>
		</div>
		<!--	数据列表-->
		<div>
			<el-table :data="tableData" style="width: 100%">
				<el-table-column prop="bdst" label="工区" width="180"/>
				<el-table-column prop="allCount" label="劳务实名打卡" width="180"/>
				<el-table-column prop="clockCount" label="人员定位打卡" width="180"/>
				<el-table-column prop="noClockCount" label="未打卡" width="180"/>
				<el-table-column prop="clockRate" label="人员定位打卡率" width="180"/>
				<el-table-column prop="#" label="详情">
					<template #default="scope">
						<el-button
							link
							type="primary"
							size="small"
							@click="get_detail(scope.row)"> 查看详情
						</el-button>

						<el-button
							link
							type="danger"
							size="small"
							@click="remove_class(scope.row)"> 调整
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!--	打卡详情-->
		<div>
			<el-dialog
				v-model="dialogVisible"
				title="详情"
				width="80%"
			>
				<div>
					<el-collapse v-model="activeName" accordion>
						<el-collapse-item v-for="(item, index) in str_result" :title="item" :name="index">
							<div v-for="(item1, index1) in arr_result[index]" :key="index1">
								<div style="margin-top: 2%">
									<el-tag style="margin-right: 5%;width: 20%;text-align: center">{{ item1.peoName }}</el-tag>
									<el-tag v-if='item1.gpsFlag === 0' type="danger" style="width: 20%;text-align: center">定位离线
									</el-tag>
									<el-tag v-if='item1.gpsFlag === 1' type="success" style="width: 20%;text-align: center">定位在线
									</el-tag>
								</div>

							</div>
						</el-collapse-item>

					</el-collapse>
				</div>
				<template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="dialogVisible = false"> 关闭 </el-button>
          </span>
				</template>
			</el-dialog>
		</div>
		<!--	调整班组-->
		<div>
			<el-dialog
				v-model="dialogRemoveVisible"
				title="调整班组"
				width="80%"
			>
				<div>
					<el-select v-model="atten_addr" class="m-2" placeholder="请选择班组" style="width: 60%">
						<el-option
							v-for="item in class_names"
							:key="item"
							:label="item"
							:value="item"
						/>
					</el-select>
				</div>
				<template #footer>
          <span class="dialog-footer">
	          <el-popconfirm
		          confirm-button-text="确认"
		          cancel-button-text="取消"
		          icon-color="#626AEF"
		          title="删除班组"
		          @confirm="removeClassConfirm"
		          @cancel=""
		          v-if="GET_BDST === '1'" style="margin-left: 2%"
	          >
		          <template #reference>
			          <el-button type="danger"> 删除班组</el-button>
		          </template>
						</el-popconfirm>
            <el-button type="primary" @click="closeDialogRemoveVisible"> 关闭 </el-button>
          </span>
				</template>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowDate, setNowDateNo} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			query_date: '',
			tableData: [],
			dialogVisible: false,
			activeName: '1',
			str_result: [],
			arr_result: [],
			dialogRemoveVisible: false,
			class_names: [],
			atten_addr: ''
		}
	},
	created() {
		this.query_date = setNowDate(new Date())
		this.query_clock_data()
	},
	methods: {
		query_clock_data() {
			this.axios.get('/peoGpsResult/queryClockCount', (response) => {
				this.tableData = response.obj
			}, {
				date: this.query_date
			})
		},
		get_detail(row) {
			this.dialogVisible = true
			this.axios.get('/peoGpsResult/queryDetailClock', (response) => {
				this.str_result = response.obj
				this.arr_result = response.obj2
			}, {
				dateStr: this.query_date,
				bdst: row.bdst
			})
		},
		remove_class(row) {
			this.dialogRemoveVisible = true
			this.axios.get('/peoGpsResult/queryClassByBdstTime', (response) => {
				this.class_names = response.obj
			}, {
				atten_time: this.query_date,
				bdst: row.bdst
			})
		},
		removeClassConfirm(){
			this.axios.get('/peoGpsResult/removeByAttenTimeAddr', (response) => {
				if (response.obj){
					ElMessage.success('删除成功!')
					this.atten_addr = ''
				}else {
					ElMessage.error('删除失败!')
				}
			},{
				atten_time: this.query_date,
				atten_addr: this.atten_addr
			})
		},
		closeDialogRemoveVisible(){
			this.dialogRemoveVisible = false
			this.query_clock_data()
		}
	}
}
</script>

<style>

</style>